import Siema from 'siema';

const isElementXPercentInViewport = function(el: HTMLElement, percentVisible: number): boolean {
    const rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
};

const createPrevNextLinks = (siema: Siema): void => {
    const prevLink = document.createElement('a');
    prevLink.classList.add('prev');
    prevLink.addEventListener('click', () => {
        siema.prev();
    });
    const nextLink = document.createElement('a');
    nextLink.classList.add('next');
    nextLink.addEventListener('click', () => {
        siema.next();
    });
    siema.selector.append(prevLink);
    siema.selector.append(nextLink);
}

((): void => {
    console.info('Hi!');

    const mainMenu = document.getElementById('main-menu');

    const body = document.getElementsByTagName('body').item(0);

    body.addEventListener('click', (event) => {
        if ((event.target as HTMLElement).id === 'main-menu') {
            mainMenu.classList.toggle('open');
        } else {
            mainMenu.classList.remove('open');
        }
    });

    // body.classList.toggle('grid');

    document.addEventListener('keydown', (event) => {
        if (event.keyCode === 122) {
            body.classList.toggle('grid');
        }
    });

    document.addEventListener("DOMContentLoaded", () => {
        if (document.getElementsByClassName('gallery').length > 0) {
            new Siema({
                selector: '.gallery',
                loop: true,
                onInit: function(): void {
                    const self = this as Siema;

                    createPrevNextLinks(self);

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    let resizeTimeout: any = null
                    window.addEventListener('resize', () => {
                        if(resizeTimeout) {
                            clearTimeout(resizeTimeout);
                        }
                        resizeTimeout = setTimeout(() => {
                            createPrevNextLinks(self);
                        }, 500);
                    });

                    document.addEventListener('keyup', (e) => {
                        if (isElementXPercentInViewport(self.selector, 10)) {
                            if (e.keyCode == 37) {
                                self.prev();
                            } else if (e.keyCode == 39) {
                                self.next()
                            }
                        }
                    });
                }
            });
        }
    });

})()

